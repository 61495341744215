import { JsonProperty, Serializable } from "typescript-json-serializer"
import CompanyStandardCategory from "@/types/aboutUs/CompanyStandardCategory"

@Serializable()
export default class CompanyStandard {
  @JsonProperty("standard_id") standardId?: number

  @JsonProperty({ name: "standard_categories", type: CompanyStandardCategory })
  standardCategories?: Array<CompanyStandardCategory>
}
