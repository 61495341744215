
















import { Component, Vue, Watch } from "vue-property-decorator"
import CompanyCSR from "@/types/aboutUs/CompanyCSR"
import AboutUsAPI from "@/api/aboutUs/aboutUs"
import { deserialize } from "typescript-json-serializer"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"

@Component({
  name: "CompanyCSRPage",
  components: { SectionUnderLine }
})
export default class CompanyCSRPage extends Vue {
  private csr: CompanyCSR = new CompanyCSR()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    AboutUsAPI.getCompanyCSR().then(({ data }) => {
      this.csr = deserialize<CompanyCSR>(data, CompanyCSR)
    })
  }

  mounted() {
    this.fetchData()
  }
}
