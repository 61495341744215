












































import { Component, Vue, Watch } from "vue-property-decorator"
import CompanyVision from "@/types/aboutUs/CompanyVision"
import CompanyMission from "@/types/aboutUs/CompanyMission"
import CompanyCoreValue from "@/types/aboutUs/CompanyCoreValue"
import AboutUsAPI from "@/api/aboutUs/aboutUs"
import { deserialize } from "typescript-json-serializer"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
// @ts-ignore
import AOS from "aos"

@Component({
  name: "VisionPage",
  components: { SectionUnderLine }
})
export default class VisionPage extends Vue {
  private vision: CompanyVision = new CompanyVision()

  private mission: CompanyMission = new CompanyMission()

  private coreValue: CompanyCoreValue = new CompanyCoreValue()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    const p1 = AboutUsAPI.getCompanyVision().then(({ data }) => {
      this.vision = deserialize<CompanyVision>(data, CompanyVision)
    })

    const p2 = AboutUsAPI.getCompanyMission().then(({ data }) => {
      this.mission = deserialize<CompanyMission>(data, CompanyMission)
    })

    const p3 = AboutUsAPI.getCompanyCoreValue().then(({ data }) => {
      this.coreValue = deserialize<CompanyCoreValue>(data, CompanyCoreValue)
    })
    Promise.all([p1, p2, p3]).then(() => {
      AOS.refresh()
    })
  }

  mounted() {
    this.fetchData()
  }
}
