













































































import { Component, Watch } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"
import CompetitionStrategy from "@/components/AboutUs/CompetitionStrategyPage.vue"
import CompanyProfilePage from "@/components/AboutUs/CompanyProfilePage.vue"
import VisionPage from "@/components/AboutUs/VisionPage.vue"
import StandardPage from "@/components/AboutUs/StandardPage.vue"
import CompanyCSRPage from "@/components/AboutUs/CompanyCSRPage.vue"
import ViewWrapper from "@/views/ViewWrapper.vue"
import { mixins } from "vue-class-component"
import TabMixin from "@/types/TabMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import InputMixin from "@/types/InputMixin"

@Component({
  name: "AboutUs",
  components: {
    ViewWrapper,
    CompanyProfilePage,
    CompetitionStrategy,
    TitleShape
  }
})
export default class AboutUs extends mixins(TabMixin, InputMixin, BreakpointMixin) {
  private tab = 0

  private menus = {
    0: {
      id: 0,
      label: "companyProfile",
      hover: false,
      component: CompanyProfilePage
    },
    1: {
      id: 1,
      label: "vision",
      hover: false,
      component: VisionPage
    },
    2: {
      id: 2,
      label: "strategy",
      hover: false,
      component: CompetitionStrategy
    },
    3: {
      id: 3,
      label: "standard",
      hover: false,
      component: StandardPage
    },
    4: {
      id: 4,
      label: "csr",
      hover: false,
      component: CompanyCSRPage
    }
  }

  private changeTab(tabId: number) {
    if (tabId !== this.tab) {
      this.$router.push({
        query: {
          ...this.$route.query,
          top: "false",
          id: `${tabId}`
        }
      })
    }
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    const queryTab = this.$route.query.id
    if (queryTab) {
      this.tab = parseInt(queryTab as string, 10)
    }
    window.dispatchEvent(new Event("resize"))
  }

  getTabClass(stIdx: string) {
    const idx = parseInt(stIdx, 10)
    let cls = ""
    if (idx === 0) {
      cls = cls.concat("pl-lg-6 ml-lg-16 ml-9 ")
    }
    if (this.tab === idx) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold")
    } else {
      cls = cls.concat("font-weight-light")
    }
    return cls
  }

  private generateRoute(tabId: number) {
    return {
      name: "AboutUs",
      query: {
        ...this.$route.query,
        top: "false",
        id: `${tabId}`
      }
    }
  }
}
